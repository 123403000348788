import apidata from './apidata'
import { combineReducers } from 'redux'
import user_data from './userdata'
import customerdata from './customerdata'
import salesdata from './salesdata'
import productsdata from './products'
import storedata from './storedata'
const combinedReducer = combineReducers({
    apidata : apidata,
    userData : user_data,
    customerdata : customerdata,
    salesdata: salesdata,
    productsdata: productsdata,
    storedata: storedata 
})

export default combinedReducer