import React, { useState } from "react";
import { MDBCard, MDBCardTitle, MDBContainer } from "mdbreact";
import "semantic-ui-css/semantic.min.css";
import { useSelector } from "react-redux";
import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import Axios from "axios";
// import { Progress } from "semantic-ui-react";
function DataFetch(props) {	
	const [percentage, setp] = useState(0);
	const userdata = useSelector((state) => state.userData);
	const storedata = useSelector(state => state.storedata);
	var token = userdata && userdata.token ? userdata.token : null;
	if (!token) props.history.push("/login");
	const userCompanyId = storedata.companyId || null;

	Axios.get(process.env.REACT_APP_API_URL + "/data-status", "", {
		headers: {
			"Access-Control-Allow-Origin": "*",
			Authorization: process.env.REACT_APP_PYTHON_SECRET,
			"Company-Id": userCompanyId,
		},
	}).then((res) => {
		console.log(res);
	});
	return (
		<div className="loaderdiv">
			<MDBContainer className="data-fetching-div">
				<CircularProgressbar
					value={percentage}
					text={`${percentage}%`}
				/>
				;
				{/* {Object.keys(props.data).map(key => {
			return <MDBCard className="card-body inner gradient">
				<MDBCardTitle className="title">{key.replace(/_/g, " ")}</MDBCardTitle>
				<Progress percent={props.data[key]}  size='small' active />
				<div>
					Your <span>{key.replace(/_/g, " ")}</span> data is been fetched <span>{Math.round(props.data[key])}%</span> is been Completed
				</div>
			</MDBCard>
			})} */}
			</MDBContainer>
		</div>
	);
}
export default DataFetch;
