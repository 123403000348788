import React, { useEffect, useState } from "react";
import {
    MDBCard,
    MDBContainer,
    MDBRow,
    MDBCol,
    MDBBtn,
    MDBMedia,
    MDBInput,
    MDBLink,
    MDBModal,
    MDBModalBody,
    MDBDropdown,
    MDBDropdownToggle,
    MDBDropdownMenu,
    MDBDropdownItem,
} from "mdbreact";
import logo from "../../../assets/img/logo.png";
import userManual from "../../../assets/Documents/VIZB - User Guide.pdf";
import { useSelector } from "react-redux";
import Axios from "axios";
import toastr from "toastr";

export default function DeleteUser(props) {
    const userdata = useSelector((state) => state.userData);
    const [users, setusers] = useState(null);
    const [selectedUser, setSelectedUser] = useState(null);
    !userdata &&
        window.location.replace(
            window.location.protocol +
                "//" +
                window.location.host +
                "/login/deleteuser"
        );
    useEffect(() => {
        Axios.get(process.env.REACT_APP_NODE_URL + "/fetchAdminUsers", {
            headers: { Authentication: userdata.token },
        })
            .then((res) => {
                setusers(res.data);
            })
            .catch((err) => {
                props.history.push("login/deleteuser");
            });
    }, []);

    const deleteSuspendUser = (del) => {
        const url = del ? "/deleteuser" : "/suspenduser";
        Axios.post(
            process.env.REACT_APP_NODE_URL + url,
            { email: selectedUser.email },
            {
                headers: {
                    Authentication: userdata.token,
                },
            }
        )
            .then((res) => {
                res.status === 200 &&
                    toastr.success(res.data)
                    window.location.reload();
            })
            .catch((err) =>
                toastr.error(`Error  ${del ? "Deleting" : "Suspending"} User`)
            );
    };
    return (
        <React.Fragment>
            <div className="Login-Page Delete-User">
                <MDBContainer fluid>
                    <MDBRow>
                        <MDBCol md="3" lg="4" className="login-left">
                            <p className="login-copyright">
                                &copy; {new Date().getFullYear()} DCKAP, All
                                Rights Reserved.
                            </p>
                        </MDBCol>
                        <MDBCol md="9" lg="8" className="">
                            <div className="form-holder">
                                <div className="form-holder-inner">
                                    <MDBMedia
                                        object
                                        center
                                        src={logo}
                                        alt=""
                                        className="d-flex login-logo"
                                    />
                                    <div className="form-inner">
                                        <form>
                                            <div className="Delete-User-container mt-5">
                                                {users && (
                                                    <MDBDropdown
                                                        size="lg"
                                                        className="delete-user-dropown"
                                                    >
                                                        <MDBDropdownToggle
                                                            caret
                                                            color="primary"
                                                        >
                                                            Select User
                                                        </MDBDropdownToggle>
                                                        <MDBDropdownMenu basic>
                                                            {users.data.map(
                                                                (user) => {
                                                                    return (
                                                                        <MDBDropdownItem
                                                                            onClick={() =>
                                                                                setSelectedUser(
                                                                                    user
                                                                                )
                                                                            }
                                                                        >
                                                                            {`${user.firstName} ${user.lastName}`}
                                                                        </MDBDropdownItem>
                                                                    );
                                                                }
                                                            )}
                                                        </MDBDropdownMenu>
                                                    </MDBDropdown>
                                                )}
                                            </div>
                                            <div className="info mt-3">
                                                {selectedUser && (
                                                    <>
                                                        <table className="table table-bordered">
                                                            <tr>
                                                                <td>Name</td>
                                                                <td>
                                                                    {selectedUser.firstName +
                                                                        " " +
                                                                        selectedUser.lastName}
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>Email</td>
                                                                <td>
                                                                    {
                                                                        selectedUser.email
                                                                    }
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>Access</td>
                                                                <td>
                                                                    <div className="access-list">
                                                                        {selectedUser.access.map(
                                                                            (
                                                                                val
                                                                            ) => {
                                                                                return (
                                                                                    <span className="access-val">
                                                                                        {
                                                                                            val
                                                                                        }
                                                                                    </span>
                                                                                );
                                                                            }
                                                                        )}
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        </table>
                                                        <div className="action">
                                                            <MDBBtn
                                                                onClick={() =>
                                                                    deleteSuspendUser()
                                                                }
                                                                color="warning"
                                                            >
                                                                {selectedUser.isSuspended
                                                                    ? "Revoke User"
                                                                    : "Suspend User"}
                                                            </MDBBtn>
                                                            <MDBBtn
                                                                onClick={() =>
                                                                    deleteSuspendUser(
                                                                        true
                                                                    )
                                                                }
                                                                color="danger"
                                                            >
                                                                Delete User
                                                            </MDBBtn>
                                                        </div>
                                                    </>
                                                )}
                                            </div>
                                            <div className="grey-text"></div>
                                            {/* <div className="text-center">
												<MDBBtn
													type="submit"
													color="primary"
												>
													Login
												</MDBBtn>
											</div> */}
                                        </form>
                                    </div>
                                </div>
                                <div className="login-links">
                                    <a
                                         href={'https://vizb.notion.site/vizB-User-Guide-7a2d52ffc0ad42b4a1434a54205739ea'}
                                        rel="noopener noreferrer"
                                        target="_blank"
                                    >
                                         User Guide
                                    </a>
                                    <MDBLink to="/contact-us">
                                        Contact Us
                                    </MDBLink>
                                    <MDBLink
                                        to="/privacy-policy"
                                        target="_blank"
                                    >
                                        Privacy Policy
                                    </MDBLink>
                                    <MDBLink
                                        to="/terms-of-service"
                                        target="_blank"
                                    >
                                        Terms of Service
                                    </MDBLink>
                                </div>
                            </div>
                        </MDBCol>
                    </MDBRow>
                </MDBContainer>
            </div>
        </React.Fragment>
    );
}
