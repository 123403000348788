import React from "react";
import logo from "../../assets/img/vizb-logo.png";
import chart from "../../assets/img/createchart.png";
import askvizb from "../../assets/img/askvizb/ask-vizb-icon.svg"
import {
    MDBNavbar,
    MDBNavbarBrand,
    MDBNavbarNav,
    MDBNavbarToggler,
    MDBNavItem,
    MDBDropdown,
    MDBDropdownToggle,
    MDBDropdownMenu,
    MDBDropdownItem,
    MDBIcon,
} from "mdbreact";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { create_store_data, user_data, customer_Data, product_Data, sales_Data, update_api_data } from "../redux/actions/index";

// import Avatar from "../../assets/img/avatar-2.jpg";

function TopNavigation(props) {
    const { access } = useSelector((state) => state.userData);
    var dispatch = useDispatch();
    const logout = () => {
        dispatch(user_data(''));
        dispatch(customer_Data('', "clv"));
        dispatch(customer_Data('', "cohort"));
        dispatch(customer_Data('', "customer_scorecard"));
        dispatch(customer_Data('', "report"));
        dispatch(customer_Data('', "holiday"));
        dispatch(product_Data('', "Tabel"));
        dispatch(product_Data('', "DemandForecasting"));
        dispatch(product_Data('', "top_bottom"));
        dispatch(sales_Data('', "year"));
        dispatch(sales_Data('', "year_and_month"));
        dispatch(sales_Data('', "month_day"));
        dispatch(sales_Data('', "session"));
        dispatch(sales_Data('', "map"));
        dispatch(create_store_data('', "platform"))
        dispatch(create_store_data('', "companyId"))
        dispatch(update_api_data('', 'summary'));
        props.history.push("/login");
    };

    const toggleSideBar = () => {
        document.getElementById("sideBar").classList.toggle("show-side-bar");
    };
    let heading =  props.title || "Dashboard";
    // const path = window.location.pathname.replace("/", "").replace("-", " ");
    // if (path !== "" && props.title !== '') {
    //     heading = path;
    // }
    const userdata = useSelector((state) => state.userData);
    const storedata = useSelector(state => state.storedata);
    let platform = storedata.platform || ''

    const dataStatus =  localStorage.getItem("dataStatus") || false
    const openTab = () => {  
        let url1='https://app.vizb.ai/?user='
        let url2 = btoa(JSON.stringify({store: userdata.company, redirect:null}))
        window.open(url1.concat(url2),"_blank");  
    }  
    const openUserGuide = () =>{
        window.open('https://vizb.notion.site/vizB-User-Guide-7a2d52ffc0ad42b4a1434a54205739ea',"_blank");  
    }
    return (
        <MDBNavbar
            className="flexible-navbar"
            light
            expand="lg"
           // scrolling="fixed"
            fixed="top"
        >
            <MDBNavbarToggler color="red" onClick={toggleSideBar}>
                <MDBIcon icon="bars" />
            </MDBNavbarToggler>

            <MDBNavbarBrand>
                <h1 className="page-title">{heading}</h1>
            </MDBNavbarBrand>
            <MDBNavbarBrand href="/" className="d-block d-lg-none mobile-logo">
                <img alt="VIZB Logo" className="img-fluid" src={logo} />
            </MDBNavbarBrand>
            <MDBNavbarNav right>
                <MDBNavItem  style={{marginTop: "10px",  'cursor' : 'pointer'}}>
                    <span className="refresh-group">
                        <span title={dataStatus == true ? "Data update is in progress": "Data is updated"} className="refresh-span"><i className="fas fa-recycle"></i></span>
                        <span className={dataStatus == true ? "refresh-badge-red" : "refresh-badge-green"}></span>
                        </span>
                </MDBNavItem>
               
                <MDBNavItem  style={{marginTop: "10px", paddingRight:"5px"}}>
                    <MDBDropdown className="">
                        <MDBDropdownToggle  className="notification-toggle" nav>
                            <span className="notfication-icon-span"><i className="fa fa-bell" aria-hidden="true"></i></span>
                            <span className={"refresh-badge-red"}></span>
                           
                       </MDBDropdownToggle>
                        <MDBDropdownMenu className="dropdown-default notification-items" right>
                            <span>We have added the Ability to create custom charts and custom dashboard in vizB, <a style={{fontSize: '15px', fontWeight:700}}  href="https://www.vizb.ai/blog/index.php/2021/09/24/custom-dashboards-in-vizb/" target="_blank">click here</a> to know more </span>
                            <MDBDropdownItem divider />
                      
                        </MDBDropdownMenu>
                    </MDBDropdown>
                </MDBNavItem>
                <MDBNavItem  style={{marginTop: "10px",  'cursor' : 'pointer'}} onClick={() =>props.history.push("/custom-dashboard")}>
                <img src={chart} width="30px" style={{marginRight:'10px'}}/>
                        {/* <span className="notfication-icon-span"><img src={chart} /></span> */}
                </MDBNavItem>
                <MDBNavItem style={{ 'cursor' : 'pointer' }} onClick={() =>props.history.push("/ask-vizb")}>
                    <img src={askvizb} className="img-fluid" alt="ask-vizb" />
                    <span>Ask VizB</span>
                </MDBNavItem>
                <MDBNavItem>
                    <MDBDropdown className="top-drop-down">
                        <MDBDropdownToggle className="dopdown-toggle top-username" nav>
                            <img
                                src={
                                    "https://cdn3.iconfinder.com/data/icons/avatars-round-flat/33/avat-01-512.png"
                                }
                                className="rounded-circle z-depth-0 top-avatar"
                                style={{ height: "35px", padding: 0 }}
                                alt=""
                            />
                            <span className="top-username-span">{ userdata.firstName + ' ' + userdata.lastName } <MDBIcon icon="chevron-down" /></span>
                        </MDBDropdownToggle>
                        <MDBDropdownMenu className="dropdown-default" right>
                            {/* <MDBDropdownItem
                                onClick={() =>
                                    props.history.push("/my-account")
                                }
                            >
                                My account
                            </MDBDropdownItem> */}
                            {/* <MDBDropdownItem
                                onClick={() =>
                                    props.history.push("/payment")
                                }
                            >
                                Payment
                            </MDBDropdownItem> */}
                            <MDBDropdownItem
                                onClick={() =>
                                    props.history.push("/connectors")
                                }
                            >
                                Connectors
                            </MDBDropdownItem>
                            {/* {userdata &&
                                userdata.access &&
                                userdata.access.includes("audit-log") && (
                                    <MDBDropdownItem
                                        onClick={() =>
                                            props.history.push("/audit-log")
                                        }
                                    >
                                        Audit Log
                                    </MDBDropdownItem>
                            )}  */}
                            {access && access.includes("manageuser") && (
                                <MDBDropdownItem
                                    onClick={() =>
                                        props.history.push("/user-Management")
                                    }
                                >
                                    User Management
                                </MDBDropdownItem>
                             )} 
                            {access && access.includes("kpi") && (
                                <MDBDropdownItem
                                    onClick={() =>
                                        props.history.push("/define-kpi")
                                    }
                                >
                                    Define KPI
                                </MDBDropdownItem>
                             )} 
                            {JSON.parse(JSON.stringify(platform)) === 'shopify' && <MDBDropdownItem onClick={(e)=>openTab()}>
                                Open in new tab
                            </MDBDropdownItem>}
                            <MDBDropdownItem onClick={(e)=>openUserGuide()}>
                                User Guide
                            </MDBDropdownItem>
                            <MDBDropdownItem onClick={logout}>
                                Log out
                            </MDBDropdownItem>
                        </MDBDropdownMenu>
                    </MDBDropdown>
                </MDBNavItem>
                
            </MDBNavbarNav>
        </MDBNavbar>
    );
}

export default TopNavigation;
