import React from "react";
import axios from "axios";
function emailVerification(props) {
	!props.match.params.token && props.history.push("/login");
	axios
		.get(process.env.REACT_APP_NODE_URL + "/verifyuser", {
			headers: { Authentication: props.match.params.token },
		})
		.then((res) => {
			if (res.status === 200) {
				// setTimeout(function () {
				// 	window.location.href =
				// 		window.location.protocol +
				// 		"//" +
				// 		window.location.host +
				// 		"/login";
				// }, 10000);
			}
		});
	return (
		<React.Fragment>
			<div className="verification-container">
				<div className="verification-info">
					<h1>Welcome To vizB.ai</h1>

					<p>
						{" "}
						You Have Been successfully verified, You will be
						automatically redirected to Login Page{" "}
					</p>
					<span
						className="btn-primary btn Ripple-parent verification-btn"
						onClick={() => {
							window.location.href =
								window.location.protocol +
								"//" +
								window.location.host +
								"/login";
						}}
					>
						Click Here
						<div
							data-test="waves"
							className="Ripple"
							style={
								({ top: 0 },
								{ left: 0 },
								{ width: 0 },
								{ height: 0 })
							}
						></div>
					</span>
				</div>
			</div>
		</React.Fragment>
	);
}

export default emailVerification;
