import React, { useState } from "react";
import "./../../assets/scss/login-signup.scss";
import logo from "../../assets/img/logo.png";
import userManual from "../../assets/Documents/VIZB - User Guide.pdf";
import toastr from "toastr";
import {
    MDBBtn,
    MDBContainer,
    MDBRow,
    MDBInput,
    MDBCol,
    MDBMedia,
    MDBIcon,
    MDBLink,
} from "mdbreact";
import axios from "axios";

function Register(props) {
    const [body, setbody] = useState({
        firstName: "",
        lastName: "",
        company: "",
        email: "",
        password: "",
        userType: "0",
        emailNotification: "*",
        access: ["dashboard", "custom-dashboard","summary","sales","customer", "products", "ab-cart","forecasting", "ppi", "reports","manageuser", "kpi"],
        verified: true,
        isdeleted: false
    });
    const [companyvalidation, setcompany] = useState("");
    const [passvalidation, setpassval] = useState("");
    const [emailvalidation, setemail] = useState("");
    const [pass, setpass] = useState("");
    const domain = window.location.host.split(".")[0];
    // if(domain !== 'app') props.history.push("/login")
    const submit = (e) => {
        e.preventDefault();
        if (pass.pass === pass.pass1) {
            if (
                body.firstName &&
                body.email &&
                body.password && body.company && body.lastName 
            ) {
                axios
                    .post(process.env.REACT_APP_NODE_URL + "/register", body, {
                        crossDomain: true,
                    })
                    .then((response) => {
                        toastr.success("User has been successfully created, Please login to continue")
                        props.history.push("/login")
                    })
                    .catch((error) => {
                        toastr.error(error.response.data.message)
                    });
            }
            else{
                toastr.error("Enter all fields");
            }
        }
    };
    const checkemail = (e) => {
        let value = e.target.value.toLowerCase();
        if (value) {
            axios
                .post(
                    process.env.REACT_APP_NODE_URL + "/emailexist",
                    { email: value },
                    { crossDomain: true }
                )
                .then((res) => {
                    if (res.data.data) {
                        setbody({ ...body, email: value });
                        setemail("correct");
                    } else {
                        setbody({ ...body, email: "" });
                        setemail("worng");
                    }
                })
                .catch((err) => {
                    setbody({ ...body, email: "" });
                    setemail("worng");
                });
        } else {
            setemail("");
        }
    };
    const checckpass = (e) => {
        if (pass === e.target.value) {
            setbody({ ...body, password: e.target.value });
            setpassval("correct");
        } else {
            setpassval("worng");
        }
    };
    const checkCompany = (e) => {
        let value = e.target.value.toLowerCase();
        if (value) {
            axios
                .post(
                    process.env.REACT_APP_NODE_URL + "/companyExist",
                    { company: value },
                    { crossDomain: true }
                )
                .then((res) => {
                    if (res.data.data) {
                        setbody({ ...body, company: value });
                        setcompany("correct");
                    } else {
                        setbody({ ...body, company: "" });
                        setcompany("worng");
                    }
                })
                .catch((err) => {
                    setbody({ ...body, company: "" });
                    setcompany("worng");
                });
        } else {
            setcompany("");
        }
    };
    return (
        <React.Fragment>
            <div className="register">
                <MDBContainer fluid>
                    <MDBRow>
                        <MDBCol md="3" lg="3" xl={'4'} className="login-left">
                            <div className="action-button">
                                <MDBBtn
                                    onClick={() => props.history.push("/login")}
                                >
                                    Sign In
                                </MDBBtn>
                                <MDBBtn className="active-btn">Sign Up</MDBBtn>
                            </div>
                            <p className="login-copyright">
                                &copy; {new Date().getFullYear()} DCKAP, All
                                Rights Reserved.
                            </p>
                        </MDBCol>
                        <MDBCol md="9" lg="9" xl={'8'} className="login-right">
                            <div className="form-holder">
                                <div className="form-holder-inner">
                                    <MDBMedia
                                        object
                                        center
                                        src={logo}
                                        alt=""
                                        className="d-flex login-logo"
                                    />
                                    <div className="form-inner">
                                        <form
                                            className="needs-validation"
                                            novalidate
                                        >
                                            <div className="grey-text">
                                                <MDBRow>
                                                    <MDBCol
                                                        xs="12"
                                                        md="6"
                                                        lg="6"
                                                    >
                                                        <MDBInput
                                                            label="Name"
                                                            icon="user"
                                                            group
                                                            type="text"
                                                            validate
                                                            required
                                                            error="wrong"
                                                            success="right"
                                                            onChange={(e) =>
                                                                setbody({
                                                                    ...body,
                                                                    firstName:
                                                                        e.target
                                                                            .value,
                                                                })
                                                            }
                                                        />
                                                    </MDBCol>
                                                    <MDBCol
                                                        xs="12"
                                                        md="6"
                                                        lg="6"
                                                    >
                                                        <MDBInput
                                                            label="Last Name"
                                                            icon="user"
                                                            group
                                                            type="text"
                                                            validate
                                                            required
                                                            error="wrong"
                                                            success="right"
                                                            onChange={(e) =>
                                                                setbody({
                                                                    ...body,
                                                                    lastName:
                                                                        e.target
                                                                            .value,
                                                                })
                                                            }
                                                            // eslint-disable-next-line
                                                            validate
                                                        />
                                                    </MDBCol>
                                                {/* </MDBRow>
                                                <MDBRow> */}
                                                    <MDBCol
                                                        xs="12"
                                                        md="6"
                                                        lg="6"
                                                    >
                                                        <MDBInput
                                                            label="Email"
                                                            icon="envelope"
                                                            group
                                                            type="email"
                                                            validate
                                                            required
                                                            error="wrong"
                                                            success="right"
                                                            onBlur={checkemail}
                                                            // eslint-disable-next-line
                                                            validate
                                                        />
                                                        {emailvalidation ===
                                                        "correct" ? (
                                                            <MDBIcon
                                                                icon="check"
                                                                className="icon-position right"
                                                            />
                                                        ) : emailvalidation ===
                                                          "worng" ? (
                                                            <MDBIcon
                                                                icon="times"
                                                                className="icon-position wrong"
                                                            />
                                                        ) : null}
                                                    </MDBCol>
                                                    <MDBCol
                                                        xs="12"
                                                        md="6"
                                                        lg="6"
                                                        // className="domain-link"
                                                    >
                                                        <MDBInput
                                                            label="Company"
                                                            icon="link"
                                                            group
                                                            type="text"
                                                            validate
                                                            required
                                                            error="wrong"
                                                            success="right"
                                                            // className="Domain-Input"
                                                            onChange={(e) =>
                                                                setbody({
                                                                    ...body,
                                                                    company:
                                                                        e.target
                                                                            .value,
                                                                })
                                                            }
                                                            // onBlur={
                                                            //     checkCompany
                                                            // }
                                                            eslint-disable-next-line
                                                            validate
                                                        />
                                                        {/* <div className="domain-suffix">
                                                            .vizb.ai
                                                        </div> */}
                                                        {/* {companyvalidation ===
                                                        "correct" ? (
                                                            <MDBIcon
                                                                icon="check"
                                                                className="icon-position1 right"
                                                            />
                                                        ) : companyvalidation ===
                                                          "worng" ? (
                                                            <MDBIcon
                                                                icon="times"
                                                                className="icon-position1 wrong"
                                                            />
                                                        ) : null} */}
                                                    </MDBCol>
                                                </MDBRow>
                                                <MDBRow>
                                                    <MDBCol
                                                        xs="12"
                                                        md="6"
                                                        lg="6"
                                                    >
                                                        <MDBInput
                                                            label="Password"
                                                            icon="lock"
                                                            group
                                                            type="password"
                                                            validate
                                                            required
                                                            error="wrong"
                                                            success="right"
                                                            onChange={(e) =>
                                                                setpass(
                                                                    e.target
                                                                        .value
                                                                )
                                                            }
                                                            // eslint-disable-next-line
                                                            validate
                                                        />
                                                    </MDBCol>
                                                    <MDBCol
                                                        xs="12"
                                                        md="6"
                                                        lg="6"
                                                    >
                                                        <MDBInput
                                                            label="Confirm Password"
                                                            icon="lock"
                                                            group
                                                            type="password"
                                                            validate
                                                            required
                                                            error="wrong"
                                                            success="right"
                                                            onChange={
                                                                checckpass
                                                            }
                                                            // eslint-disable-next-line
                                                            validate
                                                        />
                                                        {passvalidation ===
                                                        "correct" ? (
                                                            <MDBIcon
                                                                icon="check"
                                                                className="icon-position right"
                                                            />
                                                        ) : passvalidation ===
                                                          "worng" ? (
                                                            <MDBIcon
                                                                icon="times"
                                                                className="icon-position wrong"
                                                            />
                                                        ) : null}
                                                    </MDBCol>
                                                </MDBRow>
                                            </div>
                                            <div className="text-center">
                                                <MDBBtn
                                                    onClick={submit}
                                                    color="primary"
                                                >
                                                    Register Now
                                                </MDBBtn>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                                <div className="login-links">
                                    <a  href={'https://vizb.notion.site/vizB-User-Guide-7a2d52ffc0ad42b4a1434a54205739ea'} target="_blank">
                                    User Guide
                                    </a>
                                    <MDBLink to="/contact-us">
                                        Contact Us
                                    </MDBLink>
                                    <MDBLink
                                        to="/privacy-policy"
                                        target="_blank"
                                    >
                                        Privacy Policy
                                    </MDBLink>
                                    <MDBLink
                                        to="/terms-of-service"
                                        target="_blank"
                                    >
                                        Terms of Service
                                    </MDBLink>
                                </div>
                            </div>
                        </MDBCol>
                    </MDBRow>
                </MDBContainer>
            </div>
        </React.Fragment>
    );
}
export default Register;
