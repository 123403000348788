export const update_api_data = (val, key) => {
    return {
        type : 'UPDATE_API_DATA',
        val : val,
        key : key
    }
}

export const create_api_data = (val, key) => {
    return {
        type : 'CREATE_API_DATA',
        val : val,
        key : key
    }
}

export const customer_Data = (val, key) => {
    return {
        type : 'CUSTOMER_DATA',
        val : val,
        key : key
    }
}

export const product_Data = (val, key) => {
    return {
        type : 'PRODUCTS_DATA',
        val : val,
        key : key
    }
}

export const sales_Data = (val, key) => {
    return {
        type : 'SALES_DATA',
        val : val,
        key : key
    }
}

export const user_data = (userdata) => {
    return {
        type : 'USER_DATA',
        api_user_data : userdata
    }
}

export const create_store_data = (val, key) => {
    return {
        type : 'CREATE_STORE_DATA',
        val : val,
        key : key
    }
}
export const update_store_data = (val, key) => {
    return {
        type : 'UPDATE_STORE_DATA',
        val : val,
        key : key
    }
}