import React, { useState, useEffect } from "react";
import logo from "../../assets/img/vizb-logo.png";
import { MDBListGroup, MDBListGroupItem, MDBIcon, MDBBtn, MDBDropdown, MDBDropdownItem, MDBDropdownMenu, MDBDropdownToggle } from "mdbreact";
import { NavLink } from "react-router-dom";
import dashboardIcon from "../../assets/img/sidebar/dashboard.png";
import customerIcon from "../../assets/img/sidebar/customer.png";
import forecastingIcon from "../../assets/img/sidebar/forecasting.png";
import productIcon from "../../assets/img/sidebar/product-icon.png";
import esIcon from "../../assets/img/sidebar/executive.png";
import salesIcon from "../../assets/img/sidebar/sales.png";
import sentiment from "../../assets/img/sidebar/sentiment-icon.png";
import statusIcon from "../../assets/img/sidebar/status.png";
import supplierIcon from "../../assets/img/sidebar/supplier-insights.svg";
import custDashIcon from "../../assets/img/sidebar/cust-dash.png";
import abCartIcon from "../../assets/img/sidebar/abcart1.png";
import { useSelector } from "react-redux";

function TopNavigation() {
  const { access } = useSelector(state => state.userData);
  const [showMenu, setmenu] = useState(false);

  const hoverSideBar = () => {
    document.getElementById("sideBar").classList.toggle("collapsed2");
  };

  const clickSideBar = () => {
    document.getElementById("sideBar").classList.toggle("collapsed");
  };

  const toggleSideBar = () => {
    document.getElementById("sideBar").classList.toggle("show-side-bar");
  };

  const menuVis = showMenu ? "show" : "hide";
  const storedata = useSelector(state => state.storedata);
  let platform = storedata.platform || ''
  return (
    <div
      id="sideBar"
      className="sidebar-fixed position-fixed clearfix"
      // title={`${menuVis}`}
    >
      <a href="/" className="logo-wrapper waves-effect d-none d-lg-block">
        <img alt="VIZB Logo" className="img-fluid" src={logo} />
      </a>
      <MDBListGroup className="list-group-flush">
        {access && access.includes("dashboard") && (
          <NavLink
            exact={true}
            to="/"
            activeClassName="activeClass"
            onMouseEnter={() => {
              hoverSideBar();
            }}
            onMouseLeave={() => {
              hoverSideBar();
            }}
          >
            <MDBListGroupItem>
              <span className="iconHolder">
                <img
                  className="img-fluid"
                  src={dashboardIcon}
                  alt="Dashboard"
                />
              </span>
              Dashboard
            </MDBListGroupItem>
          </NavLink>
        )}
         {access && access.includes("custom-dashboard") && (
         <NavLink
            exact={true}
            to="/saved-dashboard"
            activeClassName="activeClass"
          >
            <MDBListGroupItem>
              <span className="iconHolder">
                <img
                  className="img-fluid"
                  src={custDashIcon}
                  alt="custom-Dashboard"
                />
              </span>
              Custom Dashboards
            </MDBListGroupItem>
         
          </NavLink>
         )}
        {access && access.includes("summary") && JSON.parse(JSON.stringify(platform)) !== 'p21' && (
          <NavLink
            to="/executive-summary"
            activeClassName="activeClass"
            onMouseEnter={() => {
              hoverSideBar();
            }}
            onMouseLeave={() => {
              hoverSideBar();
            }}
          >
            <MDBListGroupItem>
              <span className="iconHolder">
                <img
                  className="img-fluid"
                  src={esIcon}
                  alt="Executive Summary"
                />
              </span>
              Executive Summary
            </MDBListGroupItem>
          </NavLink>
        )}
        {access && access.includes("sales") && (
          <NavLink
            to="/sales"
            activeClassName="activeClass"
            onMouseEnter={() => {
              hoverSideBar();
            }}
            onMouseLeave={() => {
              hoverSideBar();
            }}
          >
            <MDBListGroupItem>
              <span className="iconHolder">
                <img className="img-fluid" src={salesIcon} alt="Sales" />
              </span>
              Sales
            </MDBListGroupItem>
          </NavLink>
        )}
        {access && access.includes("customer") && (
          <NavLink
            to="/customer"
            activeClassName="activeClass"
            onMouseEnter={() => {
              hoverSideBar();
            }}
            onMouseLeave={() => {
              hoverSideBar();
            }}
          >
            <MDBListGroupItem>
              <span className="iconHolder">
                <img className="img-fluid" src={customerIcon} alt="Customer" />
              </span>
              Customer
            </MDBListGroupItem>
          </NavLink>
        )}
        {access && access.includes("products")  && JSON.parse(JSON.stringify(platform)) !== 'p21' && (
          <NavLink
            to="/products"
            activeClassName="activeClass"
            onMouseEnter={() => {
              hoverSideBar();
            }}
            onMouseLeave={() => {
              hoverSideBar();
            }}
          >
            <MDBListGroupItem>
              <span className="iconHolder">
                <img className="img-fluid" src={productIcon} alt="Products" />
              </span>
              Products
            </MDBListGroupItem>
          </NavLink>
        )}
        {access && access.includes("ab-cart") && (
         <NavLink
            to="/ab-cart"
            activeClassName="activeClass"
            onMouseEnter={() => {
              hoverSideBar();
            }}
            onMouseLeave={() => {
              hoverSideBar();
            }}
          >
            <MDBListGroupItem>
              <span className="iconHolder">
                <img className="img-fluid" src={abCartIcon} alt="ab_cart" />
              </span>
              Abandoned Cart
            </MDBListGroupItem>
          </NavLink>
        )}
        {access && access.includes("forecasting") && (
          <NavLink
            to="/forecasting"
            activeClassName="activeClass"
            onMouseEnter={() => {
              hoverSideBar();
            }}
            onMouseLeave={() => {
              hoverSideBar();
            }}
          >
            <MDBListGroupItem>
              <span className="iconHolder">
                <img
                  className="img-fluid"
                  src={forecastingIcon}
                  alt="Forcasting"
                />
              </span>
              Forecasting
            </MDBListGroupItem>
          </NavLink>
        )}
        {/* {access && access.includes("kpi") && JSON.parse(JSON.stringify(platform)) !== 'p21' && (
          <NavLink
            to="/kpi"
            activeClassName="activeClass"
            onMouseEnter={() => {
              hoverSideBar();
            }}
            onMouseLeave={() => {
              hoverSideBar();
            }}
          >
            <MDBListGroupItem>
              <span className="iconHolder">
                <img className="img-fluid" src={kpiIcon} alt="KPI" />
              </span>
              KPI
            </MDBListGroupItem>
          </NavLink>
        )} */}
  {access && access.includes("ppi")  && JSON.parse(JSON.stringify(platform)) !== 'p21'  &&
        <NavLink
          to="/ppi"
          activeClassName="activeClass"
          onMouseEnter={() => {
            hoverSideBar();
          }}
          onMouseLeave={() => {
            hoverSideBar();
          }}
        >
          <MDBListGroupItem>
            <span className="iconHolder">
              <img className="img-fluid" src={statusIcon} alt="PPI" />
            </span>
            PPI
          </MDBListGroupItem>
        </NavLink>
}

        {JSON.parse(JSON.stringify(platform)) == 'p21' && access.includes("suppliers") ? <NavLink
          to="/suppliers"
          activeClassName="activeClass"
          onMouseEnter={() => {
            hoverSideBar();
          }}
          onMouseLeave={() => {
            hoverSideBar();
          }}
        >
          <MDBListGroupItem>
            <span className="iconHolder">
              <img className="img-fluid" src={supplierIcon} alt="PPI" />
            </span>
            Suppliers
          </MDBListGroupItem>
        </NavLink>
        : ''}
        
        {access && access.includes("reports")  && <NavLink
            to="/reports"
            activeClassName="activeClass"
            onMouseEnter={() => {
              hoverSideBar();
            }}
            onMouseLeave={() => {
              hoverSideBar();
            }}
          >
            <MDBListGroupItem>
              <span className="iconHolder">
                <img
                  className="img-fluid"
                  src={esIcon}
                  alt="Forcasting"
                />
              </span>
              Reports
            </MDBListGroupItem>
          </NavLink>
}
        {/* <NavLink to="/sentiment-analysis" activeClassName="activeClass" onMouseEnter={() => {hoverSideBar()}} onMouseLeave={() => {hoverSideBar()}}>
                        <MDBListGroupItem>
                            <span className="iconHolder"><img className="img-fluid" src={sentimentIcon} alt="Sentiment" /></span>
                            Sentiment
                        </MDBListGroupItem>
                    </NavLink> */}
      </MDBListGroup>
      <MDBBtn
        id="desktopCloseBtn"
        className="collapse-btn d-none d-lg-block bottom-btn"
        rounded
        onClick={() => clickSideBar()}
      >
        <MDBIcon id="desktopCloseBtnIcon" icon="angle-double-right" />
      </MDBBtn>
      <MDBBtn
        id="mobileCloseBtn"
        className="collapse-btn d-block d-lg-none bottom-btn"
        rounded
        onClick={() => toggleSideBar()}
      >
        <MDBIcon id="mobileCloseBtnIcon" icon="angle-double-left" />
      </MDBBtn>
    </div>
  );
}

export default TopNavigation;
