import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { BrowserRouter as Router, Route } from "react-router-dom";
import TopNavigation from "./includes/topNavigation";
import SideNavigation from "./includes/sideNavigation";
import Footer from "./includes/Footer";

export const RouterwithLayout = ({ component: Component, ...res }) => {
	const userdata = useSelector(state => state.userData);
	// if (res.title) {
	// 	document.title = res.title;
	// } else {
	// 	document.title = "Welcome To vizB";
	// }
	return (
		<React.Fragment>
			<Router></Router>
			<Route
				{...res}
				render={(props) => {
					var token = userdata && userdata.token ? userdata.token : null;
					if (!token) props.history.push("/login");
					return (
						<div className="flexible-content">
							<TopNavigation history={props.history} {...props} {...res} />
							<SideNavigation />
							<main id="content" className="p-5">
								<Component {...props} />
							</main>
							<Footer />
						</div>
					);
				}}
			/>
		</React.Fragment>
	);
};
