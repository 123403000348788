const customerdata = (state = [], action) => {
    switch(action.type){
        case 'CUSTOMER_DATA' :
            var key = action.key
            var val = action.val
            var updatedata = {
               [key] : val
            }
            return {...state, ...updatedata}
        default :
            return state
    }
}

export default customerdata