import React, { useState } from "react";
import axios from "axios";
import { MDBIcon } from "mdbreact";
import toastr from "toastr";
export default function ResetPassword(props) {
	let token = props.match.params.token;
	!token && props.history.push("/");
	const [password, setpassword] = useState("");
	const [cpassword, setcpassword] = useState("");
	const resetpass = () => {
		if (password === cpassword) {
			axios
				.post(
					process.env.REACT_APP_NODE_URL + "/reset-password",
					{ password },
					{
						headers: { Authentication: token },
					}
				)
				.then((res) => {
					if (res.status === 200) {
						toastr.success("Password Changed Successfully")
						props.history.push("/");
					} else {
						try {
							toastr.error(res.data.message)
							props.history.push("/");
						} catch (error) {
							console.log(error);
							toastr.error("Something went wrong. Try again")
						}
					}
				})
				.catch((err) => {
					try {
						toastr.error(err.response.data.message)
						
					} catch (error) {
						toastr.error("Something went wrong. Try again")
					}
				});
		}
	};
	return (
		<React.Fragment>
			<div className="verification-container">
				<div className="verification-info">
					<MDBIcon
						className="reset"
						style={{ "font-size": "50px" }}
						icon="key"
					/>
					<h1>Reset Password</h1>
					<form>
						<div className="custom-input">
							<input
								placeholder=" "
								id="password"
								type="password"
								onChange={(e) => setpassword(e.target.value)}
							/>
							<label htmlFor="password" className="text-label">
								Password
							</label>
						</div>
						<div className="custom-input">
							<input
								placeholder=" "
								type="password"
								id="cpassword"
								onChange={(e) => setcpassword(e.target.value)}
							/>
							<label className="text-label" htmlFor="cpassword">
								Confirm Password
							</label>
							<span
								style={{
									position: "absolute",
									top: "15px",
									right: "15px",
									"font-size": "17px",
									// display: 'none'
								}}
								id="validation"
							>
								{cpassword &&
									(password === cpassword ? (
										<MDBIcon
											style={{
												color: "green",
											}}
											far
											icon="check-circle"
										/>
									) : (
										<MDBIcon
											style={{
												color: "red",
											}}
											far
											icon="times-circle"
										/>
									))}
							</span>
						</div>
						<span
							className="btn-primary btn Ripple-parent verification-btn"
							onClick={resetpass}
						>
							Click Here
						</span>
					</form>
				</div>
			</div>
		</React.Fragment>
	);
}
