import React, { useState, useEffect } from "react";
import "./../../assets/scss/login-signup.scss";
// import logo from "../../assets/img/dckap-black.png";
import logo from "../../assets/img/logo.png";
// import googleIcon from "../../assets/img/google-icon.png";
import userManual from "../../assets/Documents/VIZB - User Guide.pdf";
import toastr  from "toastr";
import {
    MDBBtn,
    MDBContainer,
    MDBRow,
    MDBInput,
    MDBCol,
    MDBMedia,
    MDBLink,
    MDBModal,
    MDBModalBody,
} from "mdbreact";
import { useDispatch } from "react-redux";
import { user_data, create_store_data, update_store_data } from "../redux/actions/index";
import axios from "axios";
import { useParams } from "react-router-dom";

function Login(props) {
    let { redirection, user } = useParams();
    var dispatch = useDispatch();
    const [forgotpassval, setforgotpassval] = useState({});
    let domain = window.location.host.split(".")[0];
    domain = domain.includes("localhost") ? "localhost" : domain;
    // const domain = "app";
    const [body, setbody] = useState({
        email: "",
        password: "",
        domain: domain,
    });
    const [modal, setModal] = useState(false);
    const [platform, setPlatform] = useState([]);
    const [userdata, setUserdata] = useState([]);
    const [redirect, setRedirect] = useState('')
    const getCurrencySymbol = (key) =>{
        if(key == 'CNY'){
            return '¥'
        }else if(key == 'EGP'){
            return '£'
        }else if(key == 'EUR'){
            return '€'
        }else if(key == 'INR'){
            return '₹'
        }else if(key == 'GBP'){
            return '£'
        }else{
            return '$'
        }
    }
    useEffect(() => {
        localStorage.setItem('tax', 'true')
        let location = window.location.search || null
        let store = document.getElementById('shopName') ? document.getElementById('shopName').value: null;
        let redirect = null;
        if(location){
                let urlParams = location ? location.replace('?user=', '') : null
                try{
                    let user = atob(urlParams);
                    let userCredentials = JSON.parse(user);
                    if(user.includes('password')){
                        submit('', '', userCredentials.name, userCredentials.password, userCredentials.redirect)
                    }else if(user.includes('store')){
                        store = userCredentials.store
                    }
                    console.log("redirect", userCredentials.redirect)
                    redirect = userCredentials.redirect
                    setRedirect(userCredentials.redirect)
                }catch{
                    user = null
                } 
        }
        if(store){
            axios.post(process.env.REACT_APP_NODE_URL +  "/autoLogin", {store:store}, {crossDomain: true})
            .then((response) => {
                if(response.data.status === 200 && response.data.message === 'User not registered'){
                    localStorage.setItem('storeData',JSON.stringify(response.data.store))
                    props.history.push("/plans")
                }else{
                    setPlatform(response.data.data.platform.split(','))
                    let data = {
                        ...response.data.data,
                        currency : response.data.data.currency ? getCurrencySymbol(response.data.data.currency) : '$'
                    }
                    dispatch(user_data(data));
                    setUserdata(data)
                    if(response.data.data.platform.split(',').length === 1){
                        dispatch(create_store_data(response.data.data.platform.split(',')[0], 'platform'))
                        dispatch(create_store_data(response.data.data.companyId, 'companyId'))
                        redirect ? props.history.push(`/${redirect}`) : props.history.push("/");
                    }
                }    
            })
            .catch((error) => {
                try {
                    toastr.error(error.response.data.message)
                } catch (error) {
                    toastr.error("Unknown Error")
                }
            });
        }
    }, []);
    var token = "";
    const submit = (e, key, username, password, redirection) => {
        console.log("redirect", redirect, redirection)
        e && e.preventDefault();
        if(key == 'demo'){
            setbody((body) => ({
                ...body,
                email:"demo@vizb.ai",
                password: "password",
            }));
            body.email ="demo@vizb.ai"
            body.password= "password"
        }
        if(username && password){
            body.email = username
            body.password= password
        }
        if(body.email == ''){
            return toastr.error("Email not found")
        }else if(body.password == ''){
            return toastr.error("Password not found")
        }
        axios.post(process.env.REACT_APP_NODE_URL + "/login", body, {crossDomain: true})
            .then((response) => {
                token = response.data.data.token;
                let data = {
                    ...response.data.data,
                    currency : response.data.data.currency ? getCurrencySymbol(response.data.data.currency) : '$'
                }
                dispatch(user_data(data));
                dispatch(create_store_data(response.data.data.companyId, 'companyId'))
                axios.post(process.env.REACT_APP_API_URL+"/ecommerce/platform",'',{
                    headers: {
                        Authorization: process.env.REACT_APP_PYTHON_SECRET,
                        "Company-id": response.data.data.companyId
                      }
                }).then((platform_response)=>{
                    if(platform_response.status === 200){
                        dispatch(create_store_data(platform_response.data.data[0], 'platform'))
                    }else{
                        dispatch(create_store_data('','platform'))
                    }
                    switch (response.status) {
                        case 200: {
                            props.history.push("/");
                            redirection
                                ? props.history.push(`/${redirection}`)
                                : redirect ? props.history.push(`/${redirect}`) : props.history.push("/");
                            break;
                        }
                        case 206: {
                            props.history.push("/");
                            // redirection
                            //     ? props.history.push(`/${redirection}`)
                            //     : props.history.push("/connectors");
                            break;
                        }
                        case 203: {
                            toastr.error("User Not Verified")
                           axios.get(process.env.REACT_APP_NODE_URL +"/resentEmail",{headers: {Authentication: token,},})
                            .then(() =>
                                toastr.success("Email Successfully Sent")
                            );
                            break;
                        }
                        default: {
                            try {
                                toastr.error(response.data.message)
                            } catch (error) {
                                toastr.error("Unknown Error")
                            }
                        }
                    }
                })

            })
            .catch((error) => {
                try {
                    toastr.error(error.response.data.message)
                } catch (error) {
                    toastr.error("Unknown Error")
                }
            });
    };
    const forgotpassword = (e) => {
        e.preventDefault();
        axios
            .post(
                process.env.REACT_APP_NODE_URL + "/create-reset-password",
                forgotpassval,
                {
                    crossDomain: true,
                }
            )
            .then((res) => {
                if (res.status === 200) {
                    toastr.success("Check your mail to reset your password")
                    props.history.push("/");
                } else {
                    try {
                        toastr.error(res.data.message)
                        props.history.push("/");
                    } catch (error) {
                        toastr.error("Unknown Error")
                    }
                }
            })
            .catch((err) => {
                try {
                    toastr.error(err.response.data.message)
                } catch (error) {
                    console.log(error);
                    toastr.error("Unknown Error")
                }
            });
    };
    const enablePlatform = (key, index) =>{
        let companyIds = userdata.companyId.split(',')
        dispatch(create_store_data(key, 'platform'))
        dispatch(create_store_data(companyIds[index], 'companyId'))
        props.history.push("/");
    }
    return (
        <React.Fragment>
            {platform.length > 1 && <div id="overlay" onClick={e=>{}}>
        <div id="overlay-text">Choose platform To Continue<div>
            {platform.map((key, index)=>{
                return <button key={index} onClick={()=>{enablePlatform(key, index)}} className="btn btn-primary btn-lg">{key}</button>
            })}
            </div></div>
      </div>}
    
      
            <div className="Login-Page">
                <MDBContainer fluid>
                    <MDBRow>
                        <MDBCol md="3" lg="4" className="login-left">
                            <div className="action-button">
                                <MDBBtn className="active-btn">Sign In</MDBBtn>
                                {domain === "app" ? (
                                    <MDBBtn
                                        onClick={() =>
                                            props.history.push("/register")
                                        }
                                    >
                                        Sign Up
                                    </MDBBtn>
                                 ) : null} 
                            </div>
                            <p className="login-copyright">
                                &copy; {new Date().getFullYear()} DCKAP, All
                                Rights Reserved.
                            </p>
                        </MDBCol>
                        <MDBCol md="9" lg="8" className="login-right">
                            <div className="form-holder">
                                <div className="form-holder-inner">
                                    <MDBMedia
                                        object
                                        center='true'
                                        src={logo}
                                        alt=""
                                        className="d-flex login-logo"
                                    />
                                    <div className="form-inner">
                                        <form onSubmit={(e) => submit()}>
                                            <div className="grey-text">
                                                <MDBInput
                                                    label="Email"
                                                    icon="user"
                                                    group
                                                    value={body.email}
                                                    type="email"
                                                    validate
                                                    error="wrong"
                                                    success="right"
                                                    onChange={(e) => {
                                                        setbody({
                                                            ...body,
                                                            email:
                                                                e.target.value,
                                                        });
                                                    }}
                                                />
                                                <MDBInput
                                                    label="Password"
                                                    icon="lock"
                                                    group
                                                    value={body.password}
                                                    type="password"
                                                    validate
                                                    onChange={(e) => {
                                                        setbody({
                                                            ...body,
                                                            password:
                                                                e.target.value,
                                                        });
                                                    }}
                                                />
                                            </div>
                                            <div className="text-center">
                                                <span
                                                    onClick={() =>
                                                        setModal(true)
                                                    }
                                                >
                                                    Forgot Password
                                                </span>
                                                <MDBBtn
                                                    type="submit"
                                                    onClick={submit}
                                                    color="primary"
                                                >
                                                    Login
                                                </MDBBtn>
                                            </div>
                                            <div className="end-design">
                                                <span></span>
                                            </div>
                                            <div className="google-signin">
                                                <MDBBtn
                                                    className="yellow"
                                                    onClick={(e) => submit(e, 'demo')}>
                                                    View Demo
                                                </MDBBtn>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                                <div className="login-links">
                                    <a
                                        href={'https://vizb.notion.site/vizB-User-Guide-7a2d52ffc0ad42b4a1434a54205739ea'}
                                        rel="noopener noreferrer"
                                        target="_blank"
                                    >
                                        User Guide
                                    </a>
                                    <MDBLink to="/contact-us">
                                        Contact Us
                                    </MDBLink>
                                    <MDBLink
                                        to="/privacy-policy"
                                        target="_blank"
                                    >
                                        Privacy Policy
                                    </MDBLink>
                                    <MDBLink
                                        to="/terms-of-service"
                                        target="_blank"
                                    >
                                        Terms of Service
                                    </MDBLink>
                                </div>
                            </div>
                        </MDBCol>
                    </MDBRow>
                </MDBContainer>
                <MDBModal
                    isOpen={modal}
                    toggle={() => {
                        setModal((modal) => !modal);
                    }}
                >
                    <MDBModalBody>
                        <div className="reset-form">
                            <h2>Reset Password</h2>
                            <hr />
                            <form onSubmit={forgotpassword}>
                                <div className="inputs">
                                    <div className="custom-input">
                                        <input
                                            placeholder=" "
                                            type="email"
                                            required
                                            id="forgotemail"
                                            onChange={(e) => {
                                                let email = e.target.value;
                                                setforgotpassval(
                                                    (forgotpassval) => ({
                                                        ...forgotpassval,
                                                        email,
                                                    })
                                                );
                                            }}
                                        />
                                        <label
                                            htmlFor="forgotemail"
                                            className="text-label"
                                        >
                                            Email Address
                                        </label>
                                    </div>
                                    <div className="custom-input">
                                        <input
                                            placeholder=" "
                                            name="company"
                                            required
                                            type="text"
                                            id="forgotcompany"
                                            onChange={(e) => {
                                                let company = e.target.value;
                                                setforgotpassval(
                                                    (forgotpassval) => ({
                                                        ...forgotpassval,
                                                        company,
                                                    })
                                                );
                                            }}
                                        />
                                        <label htmlFor="forgotcompany">
                                            Company Name
                                        </label>
                                    </div>
                                </div>
                                <hr />
                                <MDBBtn type="submit" color="primary">
                                    Reset Password
                                </MDBBtn>
                            </form>
                        </div>
                    </MDBModalBody>
                </MDBModal>
            </div>
        </React.Fragment>
    );
}
export default Login;
