import React from "react";
import ReactDOM from "react-dom";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "bootstrap-css-only/css/bootstrap.min.css";
import "mdbreact/dist/css/mdb.css";
// lasy Load
import loadable from "@loadable/component";
import "./index.css";
import "./assets/scss/mdb.scss";
import toastr from "toastr";
// import {registerServiceWorker} from "./registerServiceWorker";
import { registerServiceWorker } from "./registerServiceWorker";
import { unregister } from "./registerServiceWorker";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { HashRouter } from "react-router-dom";
import { RouterwithLayout } from "./components/routerwithLayout";
import { store } from "./components/redux/ReduxStore";
import { Provider } from "react-redux";
import createApp from '@shopify/app-bridge';
import {TitleBar} from '@shopify/app-bridge/actions';
import DataFetch from "./components/includes/datafetch";
//Pages
import Login from "./components/pages/login";
import Register from "./components/pages/register";
import ResetPassword from "./components/pages/SUPPORTING_PAGES/resetPassword";
import emailVerification from "./components/pages/SUPPORTING_PAGES/emailVerification";
import DeleteUser from "./components/pages/SUPPORTING_PAGES/DeleteUser";
let host = document.getElementById('host') ? document.getElementById('host').value: null;
if(host){
  const app = createApp({
    apiKey: 'afd085533e700b4455cb79fa83b39103',
    host: host,
  });
  const titleBarOptions = {
    title: 'vizB',
  };
  const myTitleBar = TitleBar.create(app, titleBarOptions);
}

toastr.options.closeButton = true;
toastr.options.positionClass ="toast-top-center";
const DashboardPage = loadable(() =>
  import("./components/pages/DashboardPage")
);
const SalesPage = loadable(() => import("./components/pages/SalesPage"));
const CustomerPage = loadable(() => import("./components/pages/customerPage"));
const ProductsPage = loadable(() => import("./components/pages/ProductsPage"));
const ExecutiveSummaryPage = loadable(() =>
  import("./components/pages/ExecutiveSummary/ExecutiveSummary")
);
const ForecastingPage = loadable(() =>
  import("./components/pages/ForecastingPage")
);
const MyAccount = loadable(() =>
  import("./components/pages/SUPPORTING_PAGES/my-account")
);
const ContactUsPage = loadable(() =>
  import("./components/pages/SUPPORTING_PAGES/ContactUsPage")
);
const TermsOfServicePage = loadable(() =>
  import("./components/pages/SUPPORTING_PAGES/TermsOfUsePage")
);
const PrivacyPolicyPage = loadable(() =>
  import("./components/pages/SUPPORTING_PAGES/PrivacyPolicyPage")
);
const ConnectorsPage = loadable(() =>
  import("./components/pages/Connectors/Connectors")
);
const AuditLog = loadable(() =>
  import("./components/pages/SUPPORTING_PAGES/AuditLog")
);
const ManageUser = loadable(() =>
  import("./components/pages/SUPPORTING_PAGES/manageuser")
);
const KpiDefinePage = loadable(() =>
  import("./components/pages/KpiDefinePage")
);
const KpiResultPage = loadable(() =>
  import("./components/pages/KpiResultPage")
);
const Ppi = loadable(() =>
  import("./components/pages/Ppi/Ppi")
);

const Supplier = loadable(() =>
import("./components/pages/Supplier/suppliers")
);

const Report = loadable(() =>
  import("./components/pages/Report/report")
);

const AskVizB = loadable(() =>
  import("./components/pages/AskVizB/askvizb")
);
const Payment = loadable(() =>
  import("./components/pages/Payment/payment")
);
const Dashboard = loadable(() =>
  import("./components/pages/Dashboard/Index")
);
const ViewDashboard = loadable(() =>
  import("./components/pages/Dashboard/ViewDashboard")
);
const AbCart = loadable(() =>
  import("./components/pages/AbCart/index")
);
const Share = loadable(() =>
  import("./components/pages/Share/index")
);
const Plan = loadable(() =>
  import("./components/pages/Plans/index")
);

Array.prototype.move = function (from, to) {
  this.splice(to, 0, this.splice(from, 1)[0]);
};

ReactDOM.render(
  <HashRouter>
    <Provider store={store}>
      <Router>
        <Switch>
          <Route path="/login" exact component={Login} />
          <Route path="/login/:redirection" exact component={Login} />
          <Route path="/register" exact component={Register} />
          <Route path="/contact-us" exact component={ContactUsPage} />
          <Route path="/terms-of-service" exact component={TermsOfServicePage} />
          <Route path="/privacy-policy" exact component={PrivacyPolicyPage} />
          <Route path="/emailVerification/:token" exact component={emailVerification} />
          <Route path="/emailVerification" exact component={emailVerification} />
          <Route path="/resetPassword/:token" exact component={ResetPassword} />
          <Route path="/resetPassword" exact component={ResetPassword} />
          <Route path="/deleteuser" title="Delete User" exact component={DeleteUser} />
          <Route path="/share/:id" title="Share" exact component={Share} />
          <Route path="/plans" title="Plans" exact component={Plan} />
          <RouterwithLayout path="/connectors" exact title="Connectors" component={ConnectorsPage}/>
          <RouterwithLayout path="/sales" exact title="Sales" component={SalesPage} />
          <RouterwithLayout path="/executive-summary" exact title="Executive Summary" component={ExecutiveSummaryPage} />
          <RouterwithLayout path="/customer" exact title="Customer" component={CustomerPage} />
          <RouterwithLayout path="/forecasting" title="Forecasting" exact component={ForecastingPage} />
          <RouterwithLayout path="/kpi" exact title="KPI" component={KpiResultPage} />
          <RouterwithLayout path="/fetchingdata" exact title="Fetchhing Data" component={DataFetch} />
          <RouterwithLayout path="/products" exact title="Products" component={ProductsPage} />
          <RouterwithLayout path="/my-account" exact title="My Account" component={MyAccount} />
          <RouterwithLayout path="/audit-log" exact title="Audit Log" component={AuditLog} />
          <RouterwithLayout path="/User-Management" exact title="User Management" component={ManageUser} />
          <RouterwithLayout path="/define-kpi" exact title="KPI" component={KpiDefinePage} />
          <RouterwithLayout path="/ppi" exact title="PPI" component={Ppi} />
          <RouterwithLayout path="/suppliers" exact title="Suppliers" component={Supplier} />
          <RouterwithLayout path="/reports" exact title="Reports" component={Report} />
          <RouterwithLayout path="/ask-vizb" title="Ask VizB" exact component={AskVizB} />
          <RouterwithLayout path="/payment" title="Payment" exact component={Payment} />
          <RouterwithLayout path="/custom-dashboard" exact title="Custom Dashboard" component={Dashboard} />
          <RouterwithLayout path="/saved-dashboard" exact title="View Dashboard" component={ViewDashboard} />
          <RouterwithLayout path="/ab-cart" exact title="Abandoned Cart" component={AbCart} />
          <RouterwithLayout path="/" title="Dashboard" component={DashboardPage} />
        
        </Switch>
      </Router>
    </Provider>
  </HashRouter>,
  document.getElementById("root")
);
// registerServiceWorker();
unregister();
